$base-url: '../../assets/fonts/';

// Noto Sans JP
@font-face {
	font-family: 'Noto Sans JP'; 
	src: URL($base-url + 'NotoSansJP-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Noto Sans JP'; 
	src: URL($base-url + 'NotoSansJP-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
	font-family: 'Noto Sans JP'; 
	src: URL($base-url + 'NotoSansJP-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
	font-family: 'Noto Sans JP'; 
	src: URL($base-url + 'NotoSansJP-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
	font-family: 'Noto Sans JP'; 
	src: URL($base-url + 'NotoSansJP-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
	font-family: 'Noto Sans JP'; 
	src: URL($base-url + 'NotoSansJP-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}
