@import 'src/styles/_colors-variables.scss';

/* nprogress style */
#nprogress {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 888;
  pointer-events: auto!important;
  background: rgba(0,0,0,0.1);
  .bar {
    z-index: 10000 !important;
    height: 5px !important;
    position: fixed !important;
  }
  .spinner {
    position: fixed !important;
    top: calc(50% - 9px) !important;
    left: calc(50% - 9px) !important;
  }
}

/* common style */
#nprogress {
  .bar {
    z-index: 10000 !important;
  }
}

/* common style */
body {
	background: #FBFBFF;
	height: 100%;
    min-width: 1263px;
}

input,
button {
    border-radius: 0;
}
a,
a:focus {
	text-decoration: none;
	outline: none;
    cursor: pointer;
}
	a:hover {
		text-decoration: none;
		opacity: 0.7;
	}
.icons,
.icon-before::before {
    vertical-align: middle;
    display: inline-block;
}
    .icon-before::before {
        content: "";
        display: block;
        position: absolute;
    }
    
.hide {
	display: none!important;
}
.desktop-hide {
    display: none;
}
.desktop-show {
    display: block;
}
.flex {
	display: flex;
    align-items: center;
}
.flex-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* btn */
.btn {
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    border: none;
    box-shadow: none;
    position: relative;
    text-transform: none;
    cursor: pointer;
    &.disabled {
        color: #fff;
    }
}

.btn.active,
.btn:active,
.btn:focus {
    box-shadow: none;
}

/* .btn-blue, .btn-red */
.btn-blue,
.btn-blue:hover,
.btn-blue:focus,
.btn-red,
.btn-red:hover,
.btn-red:focus {
    color: #fff;
    height: 40px;
    line-height: 38px;
    border-radius: 20px;
    width: auto;
    padding: 1px 20px;
    font-weight: 700;
}

.btn-blue {
    background: #3241FF!important;
}

.btn-blue--fade {
    background: #7b84ff!important;
    
    &:hover {
        opacity: 1 !important;
        cursor: auto;
    }
}

.btn-red {
    background: #f00!important;
}

.btn-blue:hover {
    opacity: 0.8;
}

/* .btn-border */
.btn-border,
.btn-border:hover,
.btn-border:focus {
    color: #3241FF;
    height: 40px;
    line-height: 36px;
    border-radius: 20px;
    width: auto;
    padding: 1px 20px;
}

.btn-border {
    border: 1px solid #3241FF;
    &.not-clickable {
        cursor: default!important;
        &:hover {
            opacity: 1;
        }
    }
}

.btn-border:hover {
    opacity: 0.8;
}

// .white-border-panel 
.white-border-panel {
    background: #FFFFFF;
    border: 1px solid #C9D2DF;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 0 0 30px;
    margin: 0 24px;
    overflow: hidden;
    // .padding32
    &.padding32 {
        padding: 32px;
    }
}
.table-data {
    width: auto;
    min-width: 100%;
    display: inline-table;
    &.scrollable {
        transform: rotateX(-180deg);
        .col-th,
        .col-td {
            min-width: 160px;
        }
    }
    .row-th,
    .row-td {
        display: table;
        width: 100%;
    }
    .col-td,
    .col-th {
        display: table-cell;
        vertical-align: top;
    }
    .col-th {
        vertical-align: middle;
    }
    .spacing {
        padding: 0 8px;
        word-break: break-all;
        .red-txt {
            font-weight: 700;
            font-size: 18px;
            color: #DB303F;
            line-height: 26px;
        }
        ul {
            li {
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                color: #121863;
            }
        }
        .btn-blue {
            padding: 1px 30px;
            line-height: 36px;
            min-width: 146px;
            white-space: nowrap;
        }
    }
    .center {
        text-align: center;
    }
    .blue-txt {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.015em;
        color: #121863;
    }
    .blue-link {
        @extend .blue-txt;
        text-decoration-line: underline;
        color: #3241FF;
        &:hover {
            color: #3241FF;
        }
    }
    .date-num {
        margin-right: 5px;
    }
    .row-th {
        background: rgba(50, 65, 255, 0.05);
        font-weight: 700;
        font-size: 17px;
        line-height: 17px;
        height: 60px;
        padding: 0 8px;
        .width-buttons {
            min-width: 185px;
        }
    }
    .row-td {
        padding: 16px 8px;
        border-bottom: 1px solid #C9D2DF;
        &:last-child {
            border-bottom: 0;
        }
        .col-td {
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 0.005em;
            color: #121863;
        }
        .top-num {
            font-weight: 400;
            font-size: 17px;
            line-height: 25px;
            text-align: right;
            letter-spacing: 0.005em;
            color: #121863;
        }
        .parent-num {
            letter-spacing: 0.005em;
            font-size: 12px;
            line-height: 14px;
            font-weight: 600;
            font-family: 'Roboto';
            &.green {
                color: #7AC63F;
            }
            &.red {
                color: #DC5B3E;
            }
        }
    }
    .text-left {
        text-align: left;
    }
    .font17 {
        font-size: 17px;
    }
    .text-right {
        text-align: right;
    }
    .text-center {
        text-align: center;
    }
    .sub-txt {
        color: #757799;
    }
    .ml30 {
        margin-left: 30px;
    }
    .width70 {
        width: 70%;
    }
    .width65 {
        width: 65%;
    } 
    .width25 {
        width: 25%;
    }
    .width44 {
        width: 44%;
    }
    .width29 {
        width: 29%;
    }
    .width25 {
        width: 25%;
    }
    .width16 {
        width: 16%;
    }
    .width10 {
        width: 10%;
    }
    .width11 {
        width: 11%;
    }
    .width14 {
        width: 14%;
    }
    .width10 {
        width: 10%;
    }
    .width9 {
        width: 9%;
    }
    .width7 {
        width: 7%;
    }
    .font14 * {
        font-size: 14px;
    }
    .facility-title {
        text-align: left;
        > * {
            display: inline-block;
        }
        .btn {
            margin-top: 8px;
            padding: 0 12px;
            font-size: 10px;
            line-height: 2em;
            height: auto;
            min-width: 0;
        }
    }
    .bold {
        font-weight: 700;
    }
    .top-location {
        .txt {
            font-size: 17px;
            line-height: 25px;
        }
        .bottom-txt {
            display: flex;
            align-items: center;
            .points {
                width: 5px;
                height: 5px;
                border-radius: 4px;
                background: #121863;
                margin-right: 5px;
            }
        }
    }
    .bottom-btn {
        padding-top: 8px;
        .btn-border {
            border-radius: 3px;
            line-height: 32px;
            padding: 1px 24px;
            font-size: 12px;
            height: 34px;
            min-width: 63px;
        }
    }
    .row-line {
        display: flex;
        .gray-at {
            font-size: 14px;
            color: #757799;
            font-weight: 700;
            max-width: 112px;
            flex: 0 0 112px;
        }
        .txt {
            font-size: 17px;
            color: #121863;
        }
    }
    
    .points {
        width: 4px;
        height: 4px;
        flex: 0 0 4px;
        max-width: 4px;
        background: #121863;
        border-radius: 4px;
        margin: 10px 10px 0 0;
        display: block;
        align-self: flex-start;
    }
    .p-txt {
        font-size: 14px;
        color: #121863;
    }
}

// .content-wrap
.content-wrap {
    // padding-top: 64px;
    .right-content {
        padding: 30px 24px 50px 30px;
        flex: 1;
    }
}

.loading {
    button, .btn, 
    input, textarea, 
    a[class*="btn-"] {
        pointer-events: none;
        opacity: .75;
        cursor: auto;
    }
}