@import '../../styles/_colors-variables.scss';
// .analyses-result-page
.analyses-result-page {
    .top-back {
        padding: 36px 24px;
        .icons {
            height: 24px;
        }
        .icon-back {
            background: url(../../assets/back.svg) left center no-repeat;
            background-size: 16px 16px;
            margin-right: 3px;
            color: #757799;
            font-size: 16px;
            line-height: 24px;
            padding-left: 20px;
        }
        .txt {
            color: #757799;
            font-size: 16px;
            line-height: 24px;
        }
    }
}