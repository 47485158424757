@import '../../styles/_colors-variables.scss';

// .header 
.footer {
    background: #3241FF;
    padding: 12px;

    * {
        color: white;
    }
    
    p {
        text-align: center;
        font-size: 14px;
    }
}