@import '../../../styles/_colors-variables.scss';

/* .checkbox-wrap */
.checkbox-wrap {
    display: inline-block;
    vertical-align: top;
    input[type="checkbox"]{
        display: none;

        &+label {
            font-size: 14px;
            display: inline-block;
            font-weight: normal;
            padding-left: 24px;
            text-align: left;
            position: relative;
            line-height: 16px;
            text-transform: none;
            cursor: pointer;
            .main-label {
                font-size: 15px;
                line-height: 21px;
                letter-spacing: 0.005em;
                color: #121863;
            }
            .little {
                display: block;
                font-weight: 400;
                font-size: 11px;
                line-height: 16px;
                color: #121863;
                margin-top: 4px;
            }
        }
    }
    label::before {
        border-radius: 2px;
        content: "";
        display: inlineblock;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
        border: 1px solid #3241FF;
        position: absolute;
        left: 0;
        top: 3px;
        text-align: center;
        border-radius: 4px;
    }
    input[type="checkbox"]:checked+label::before{
        background: #3241FF url(/assets/check-done.svg) center center no-repeat;
        background-size: 9px auto;
    }
    &.have-txt {
        input[type="checkbox"]+label {
            color: #373a3c;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            padding-top: 0;
            padding-left: 24px;
        } 
        label::before {
            border: 1px solid #757799;
        }
        input[type="checkbox"]:checked+label::before{
            border: 1px solid #3241FF;
        }
    }

    &.checkbox-select-all {
        input[type="checkbox"] + label {
            span.main-label {
                font-size: 13px;
                line-height: 19px;
                /* identical to box height */
    
                letter-spacing: 0.01em;
            }
        }
    }
}