@import '../../../styles/_colors-variables.scss';

.result-wordcloud {
    padding: 0 24px 24px 24px;
    .title {
        display: flex;
        font-size: 24px;
        font-weight: 700;
        align-items: center;
        padding-bottom: 25px;
        .line {
            flex: 1;
            margin-left: 24px;
            background-color: #C9D2DF;
            height: 4px;
            margin-top: 2px;
        }
    }
    .wordcloud-content {
        display: flex;
        border-radius: 16px;
        border: 1px solid #C9D2DF;
        background-color: #fff;
        min-height: 410px;
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 616px;
            height: 362px;
        }
    }
}