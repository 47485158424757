@import '../../../styles/_colors-variables.scss';

// .modal-new-analysis
.modal-default.modal-new-analysis {
    background-color: rgba(0,0,0,0.2);
    .modal-mains {
        margin: 50px auto 50px;
        width: 900px;
        height: auto;
        padding: 32px 40px;
        .btn-close {
            width: 40px;
            height: 40px;
            &:hover {
                opacity: 0.9;
            }
        }

        &__main {
            max-height: 70vh;
            overflow: scroll;
        }
        .bottom-btns {
            margin-top: 0;
            .btn {
                min-width: 160px;
            }
        }
        .row-form {
            margin-bottom: 40px;
            &.row {
                margin: 0 -8px 40px;
            }
            .col {
                padding: 0 8px;
            }
            &.mb60 {
                margin-bottom: 30px;
            }
        }
        .select-date-picker-container{
            max-width: 450px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .input-date-picker {
               border-radius: 8px;
               border: 1px solid #C9D2DF;
               padding: 0 16px;
               min-height: 40px;
            }
        }
        .select-locations-container{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .select-all {
                font-size: 12px;
                max-height: 25px;
                line-height: normal;
                padding: 2px 10px;
                border-radius: 25px;
                margin-left: 10px;
                margin-top: 6px;
            }
        }

    }
    .selected-locations-container {
        margin: 10px 0px;
        padding: 10px;
        width: 100%;
        max-height: 100px;
        height:fit-content;
        overflow-y: scroll;
        scrollbar-width: 3px;
        border-radius: 10px;
            border: 1px solid #C9D2DF;
    }
    .slected-location-item {
        &.selected {
            margin: 4px 0px;
            color: #121863;
            font-size: 15px;
            font-weight: 700;
            cursor: pointer;
          }
    }
    .check-lang {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .items {
            margin: 0 5px;
        }
    }
}