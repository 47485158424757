@import '../../../styles/_colors-variables.scss';

/* .inputs */
.inputs {
    height: 43px;
    padding: 0 16px;
    display: block;
    position: relative;
    border: 1px solid #C9D2DF;
    border-radius: 8px;
    &.search-box {
        padding: 0 15px;
        height: 44px;
        line-height: 38px;
        color: #121863;
        font-weight: 700;
        font-size: 16px;
        position: relative;
        padding-left: 35px;
        .icon-search {
            background: url('/assets/search.svg') center center no-repeat;
            background-size: 24px 24px;
            width: 24px;
            height: 24px;
            margin-right: 5px;
            position: absolute;
            left: 9px;
            top: 9px;
        }
    }
    input {
        font-size: 17px;
        color: var(--text-color);
        background: none;
        border: none;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        line-height: 18px;
        vertical-align: middle;
        box-shadow: none;
        resize: none;
        outline: none;
        background-color: transparent;
    }
    input::-ms-clear,
    input::-ms-reveal {
        display: none;
    }
    input::-webkit-input-placeholder {
        color: rgba(117, 119, 153, 0.7);
    }
    input:-moz-placeholder {
        color: rgba(117, 119, 153, 0.7);
    }
    input::-moz-placeholder {
        color: rgba(117, 119, 153, 0.7);
    }
    input:-ms-input-placeholder {
        color: rgba(117, 119, 153, 0.7);
    }
    &.error-txt {
        border: 1px solid #C70000;
    }
    .error {
        padding: 8px 0 0 5px;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: 0.015em;
        color: #C70000;
        position: relative;
        &:before {
            content: '';
            display: block;
            background: url(/assets/warning.svg) center center no-repeat;
            background-size: 15px 13px;
            width: 15px;
            height: 15px;
            position: absolute;
            left: -15px;
            top: 10px;
        }
    }
}