@import '../../styles/_colors-variables.scss';

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.status-wrap {
  .icons {
      margin-right: 6px;
      width: 24px;
      height: 24px;
  }
  .icon-done {
      background: url(../../assets/check_circle.svg) center center no-repeat;
      background-size: 24px 24px;
  }
  .icon-error {
      background: url(../../assets/error.svg) center center no-repeat;
      background-size: 24px 24px;
  }
  .icon-process {
      background: url(../../assets/progress.svg) center center no-repeat;
      background-size: 24px 24px;
      animation: rotation 3s linear infinite;
  }
  .icon-wait {
      background: url(../../assets/wait.svg) center center no-repeat;
      background-size: 24px 24px;
  }
  .txt {
      font-size: 14px;
      &.green {
          color: #7AC63F;
      }
      &.red {
          color: #DC5B3E;
      }
      &.orange {
          color: #F69A0E;
      }
      &.waite {
          color: #757799;
      }
  }
}