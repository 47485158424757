// .modal-default
.modal-default {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.225);
    z-index: 9999;
    .modal-mains {
        background-color: #fff;
        border-radius: 16px;
        width: 600px;
        height: 283px;
        position: relative;
        padding: 32px 40px;
        .btn-close {
            width: 40px;
            height: 40px;
            background: #121863 url('../../assets/close.svg') center center no-repeat;
            border: 1px solid #fff;
            border-radius: 100%;
            position: absolute;
            right: -15px;
            top: -15px;
            display: block;
            opacity: 1;
            &:hover {
                opacity: 0.9;
            }
        }
        .top-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 35px;
            color: #121863;
            margin-bottom: 23px;
        }
        .top-sub-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 35px;
            color: #121863;
            margin-bottom: 10px;
        }
        .title-bar {
            .label-title {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                margin-top: 15px;
                margin-bottom: 10px;
                color: #757799;
            }
            .little-txt {
                font-size: 11px;
                line-height: 16px;
                color: #121863;
                font-weight: 400;
                margin-left: 30px;
                &.required {
                    color: #C70000;
                }
            }
        }
        .inputs {
            min-height: 40px;
        }
        .bottom-btns {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .btn {
                min-width: 160px;
                margin: 0 8px;
            }
        }
    }
}