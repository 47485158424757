@import '../../styles/_colors-variables.scss';

// .pagenation
.pagenation {
    display: flex;
    padding: 40px 15px 20px 15px;
    justify-content: center;
    .center-page {
        display: flex;
        align-items: center;
        .icons {
            width: 36px;
            height: 36px;
            border-radius: 100%;
            border: 2px solid #3241FF;
            text-align: center;
            line-height: 34px;
            &.disabled {
                border-color: #757799;
                pointer-events: none;
                &.btn-prev {
                    background: url('/assets/gray-arrow .svg') center center no-repeat;
                }
                &.btn-next {
                    background: url('/assets/gray-arrow .svg') center center no-repeat;
                }
            }
        }
        .page-area {
            margin: 0 15px;
            .number-input {
              width: 40px;
              margin-right: 15px;
              height: 36px;
              padding: 0 5px;
              input {
                text-align: center;
              }

              &.focused {
                border: 2px solid #757799;

                input::placeholder {
                    color: transparent;
                }
              }
            }
            .total-num {
                font-size: 17px;
                line-height: 20px;
                color: #757799;
                font-weight: 400;
                white-space: pre;
            }
        }
        .btn-prev {
            background: url('/assets/blue-arrow.svg') center center no-repeat;
            background-size: 16px 16px;
            transform: rotate(-180deg);
        }
        .btn-next {
            background: url('/assets/blue-arrow.svg') center center no-repeat;
            background-size: 16px 16px;
        }
    }
}