
.download-btn {
    margin-bottom: 15px;
    margin-left: 25px;

    .icons {
        width: 24px;
        height: 24px;
        margin-right: 7px;
    }

    .icon-saveload-blue {
        background: url(/assets/saveload-blue.svg) no-repeat center;
        background-size: 22px 22px;
        margin-top: -3px;
    }
}