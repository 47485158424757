@import '../../../styles/_colors-variables.scss';

.result-nlp-analysis {
    padding: 0 24px 32px 24px;
    .title {
        display: flex;
        font-size: 24px;
        font-weight: 700;
        align-items: center;
        margin-right: 15px;
        .line {
            flex: 1;
            margin-left: 24px;
            background-color: #C9D2DF;
            height: 4px;
            margin-top: 2px;
        } 
    }
    .nlp-choose {
        padding: 24px 0 0;
        .row-group {
            padding-bottom: 30px;
            .titles {
                font-weight: 700;
                font-size: 17px;
                color: #000;
                min-width: 80px;
                margin-right: 25px;
            }
            .tab-btns {
                .btn-border {
                    margin-right: 10px;
                    min-width: 101px;
                    &.current {
                        background-color: #3241FF;
                        color: #fff;
                    }
                    &.min137 {
                        min-width: 137px;
                    }
                }
            }
        }
    }
    .number-of-wrap {
        .table-title {
            font-size: 18px;
            padding-bottom: 16px;
            color: #121863;
            font-weight: bold;
        }
    }
    .white-border-panel {
        margin: 0;
        .num-list {
            li {
                display: flex;
            }
        }
    }
}