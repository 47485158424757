@import '../../styles/_colors-variables.scss';

.analyses-page {
    .top-bar {
        padding: 33px 32px 32px 24px;
        .title {
            font-size: 32px;
            font-weight: 700;
            line-height: 42px;
        }
        .btn-border {
            padding: 1px 24px;
        }
        .icon-add {
            width: 23px;
            height: 23px;
            background: url('/assets/blue-add.svg') center center no-repeat;
            background-size: 23px 23px;
            margin-right: 8px;
            margin-top: -4px;
        }
        .right-buttons-container {
            min-width: 410px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}