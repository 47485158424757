@import '../../../styles/_colors-variables.scss';

.result-overview {
    .top-uuid {
        padding: 0 24px 16px 24px;
        font-size: 32px;
        font-weight: 700;
        line-height: 47px;
    }
    
    .lang-tag {
        text-transform: capitalize;
    }
}