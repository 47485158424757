@import '../../styles/_colors-variables.scss';

.check-groups {
    margin-bottom: 40px;
    &.mb-54 {
        margin-bottom: 50px;
    }
    .blue-block-row {
        margin-bottom: 30px;
        &.mb-14 {
            margin-bottom: 14px;
        }
        .blue-block {
            padding: 4px 16px;
            line-height: 26px;
            font-size: 18px;
            font-weight: 700;
            margin-right: 24px;
            background: #121863;
            border-radius: 4px;
            color: #fff;
        }
        .bold-title {
            font-size: 17px;
            font-weight: 700;
            line-height: 26px;
            color: #121863;
            margin-right: 20px;
        }
    }
    .select-items {
        margin-bottom: 24px;
    }
    .check-list {
        ul {
            display: grid;
            grid-gap: 12px 15px;
            grid-template-columns: repeat( auto-fill, minmax(165px, 1fr));
        }
    }

    &-horizontal {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
        grid-gap: 16px;

        .check-groups {
            margin-bottom: 0;
        }

        .check-list ul {
            grid-template-columns: 1fr;
        }
    }
}