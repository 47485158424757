@import '../../../styles/_colors-variables.scss';

.result-stats {
    padding: 32px 24px 32px 24px;
    .title {
        display: flex;
        font-size: 24px;
        font-weight: 700;
        align-items: center;
        .line {
            flex: 1;
            margin-left: 24px;
            background-color: #C9D2DF;
            height: 4px;
            margin-top: 2px;
        }
    }
    .stats-content {
        padding: 24px 0 0 0;
        .charts {
            border-radius: 16px;
            border: 1px solid #C9D2DF;
            background-color: #fff;
            min-height: 410px;
            padding: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .chart-title {
                position: absolute;
                top: 30px;
                color: #757799;
                font-size: 16px;
            }
        }
    }
}